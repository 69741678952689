@import '~theme/variables';

.img {
  max-width: 100%;
  margin: 0 auto;
}

.color {
  background-color: $surfaces-surface;
}
