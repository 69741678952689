@import '~theme/variables';

.modalHeader {
  color: $on-surface-high-emphasis;
  font-family: $font-family-bold;
  font-weight: 700;
  font-size: 1rem;
  border-bottom: 0.125rem solid $grayscale-5;
  padding: 1rem;
  margin: 0;
}
