@import '~theme/variables';

.container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 24.25rem;
  margin: 0 0.5rem 1rem;

  @media (max-width: 64rem) {
    width: 30rem;
  }
}

.text {
  flex: 1;
}

.description {
  color: $grayscale-3;
  font-size: 0.875rem;
  line-height: 1.375rem;
  margin: 0;
}

.header {
  color: $grayscale-1;
  font-size: 1rem;
  font-weight: 200;
  line-height: 1.25rem;
  margin: 0;
  padding: 0;
}

.img {
  color: $grayscale-3;
  display: block;
  height: 3.75rem;
  margin: 0 1rem 0 0;
  width: 3.75rem;
}
