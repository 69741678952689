@import '~theme/variables';

.section {
  padding: 2.5rem 0 1rem;
  border-top: 1px solid $light-border;
  background: $surfaces-surface;
  display: block;

  content-visibility: auto;
  contain-intrinsic-size: 1px 160px;

  @media (max-width: 28.125rem) {
    display: none;
    padding: 0;
  }
}

.container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}
