@import '~theme/variables';

.promptContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0 0;
  position: relative;
  background-color: $surfaces-background;

  @media (max-width: 31.25rem) {
    padding: 1rem 0 0;
  }
}

.promptText {
  font-size: 1rem;
  display: inline-block;
  margin: 0 1.75rem 0 0;

  @media (max-width: 60.75rem) {
    display: none;
  }
}

.icon {
  margin-right: 0.5rem;
  width: 2rem;
  height: auto;
  color: $primary-base;

  @media (max-width: 60.75rem) {
    display: none;
  }

  @media (max-width: 31.25rem) {
    width: 1.875rem;
    height: 1.875rem;
  }
}

.btnIcon {
  display: none;
  margin-right: 0.5rem;
  width: 1.5em;
  height: 1.5rem;
  color: $on-primary;

  @media (max-width: 60.75rem) {
    display: block;
  }
}

.instruction {
  @media (max-width: 31.25rem) {
    display: none;
  }
}
