@import '~theme/variables';

.container {
  margin: auto;
  max-width: 84rem;
  padding: 1rem 0;

  @media (max-width: 48rem) {
    overflow: hidden;
    width: 100%;
  }
}

.tile {
  padding: 0 0.625rem;
  text-align: center;

  /* anchor reset rules */
  color: inherit;
  text-decoration: none; /* no underline */
}

.imgContainer {
  border: $border;
  border-radius: $border-radius-categories-home;
  width: 5rem;
  height: 5rem;
  margin: 0 auto 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $surfaces-surface;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
}

@mixin categoryIcon($name) {
  background-color: $primary-base;
  mask: url($name) no-repeat center;
  mask-size: contain;
  width: 2.5rem;
  height: 2.5rem;
}

.offers {
  @include categoryIcon('/assets/icons/offers.svg');
}

.manabikh {
  @include categoryIcon('/assets/icons/manabikh.svg');
}

.fresko-kreas {
  @include categoryIcon('/assets/icons/fresko-kreas.svg');
}

.allantika {
  @include categoryIcon('/assets/icons/allantika.svg');
}

.turokomika {
  @include categoryIcon('/assets/icons/turokomika.svg');
}

.galaktokomika {
  @include categoryIcon('/assets/icons/galaktokomika.svg');
}

.eidh-psugeiou {
  @include categoryIcon('/assets/icons/eidh-psugeiou.svg');
}

.katapsuxh {
  @include categoryIcon('/assets/icons/katapsuxh.svg');
}

.pantopwleio {
  @include categoryIcon('/assets/icons/pantopwleio.svg');
}

.kaba {
  @include categoryIcon('/assets/icons/kaba.svg');
}

.proswpikh-frontida {
  @include categoryIcon('/assets/icons/proswpikh-frontida.svg');
}

.brefika {
  @include categoryIcon('/assets/icons/brefika.svg');
}

.kathariothta {
  @include categoryIcon('/assets/icons/kathariothta.svg');
}

.oikiakh-xrhsh {
  @include categoryIcon('/assets/icons/oikiakh-xrhsh.svg');
}

.pet-shop {
  @include categoryIcon('/assets/icons/pet-shop.svg');
}

.biologikaleitourgika {
  @include categoryIcon('/assets/icons/biologikaleitourgika.svg');
}

.hlektrikes-suskeues {
  @include categoryIcon('/assets/icons/hlektrikes-suskeues.svg');
}

.title {
  font-size: 0.875rem;
  margin: 0.5rem 0;
  font-family: $font-family-bold;
}

.count {
  font-size: 0.75rem;
  color: $grayscale-3;
  margin: 0;
}

.viewAllContainer {
  display: none;
  justify-content: center;
  align-items: center;
  @media (max-width: 48rem) {
    display: flex;
  }
}

.sliderContainer {
  width: 90%;
  margin: auto;
  @media (max-width: 48rem) {
    width: 110%;
  }
}

.categoriesBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.75rem;
  font-family: $font-family-bold;
  font-weight: 700;
  color: $grayscale-1;
  border: none;
}

.arrowIcon {
  width: 1.25rem;
  height: 1.25rem;
  color: $grayscale-1;
}
