.container {
  margin: 3rem auto;
  display: flex;
  justify-content: center;
}

.img {
  max-width: 100%;
  height: 11rem;
  cursor: pointer;

  @media (max-width: 700px) {
    height: 8rem;
  }

  @media (max-width: 480px) {
    height: 6rem;
  }
}
